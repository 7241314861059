import {Component, OnInit, Input, ViewChild} from '@angular/core';
import {
  CLIENT_HEADERS,
  CLIENT_TYPES,
  CLIENT_URLS,
  DIAGNOSIS_TYPES, MONTHS_LIST, ADDRESSES_URLS, ADDRESSES_HEADERS
} from '../../constants/constants';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { NbDialogService } from "@nebular/theme";
import { AuthGuard } from "../../services/auth.guard";
import { DarkModeService } from "../../services/darkmode.service";
import { DataService } from 'src/app/services/data.service';
import {TableHeaderComponent} from "../../molecule/table-header/table-header.component";
import {Building} from "../../types/building.type";

@Component({
  selector: 'app-adresses',
  templateUrl: './adresses.component.html',
  styleUrls: ['./adresses.component.scss']
})
export class AdressesComponent implements OnInit {

  public readonly CLIENT_URLS = CLIENT_URLS;
  public readonly CLIENT_HEADERS = CLIENT_HEADERS
  public readonly ADDRESSES_HEADERS = ADDRESSES_HEADERS;
  public readonly MONTHS_LIST = MONTHS_LIST;
  public readonly ADDRESSES_URLS = ADDRESSES_URLS;

  // Buildings list objects
  @Input() buildingsList: Building[] = [];

  // Table header component
  @ViewChild('tableHeaderComponent') tableHeaderComponent!: TableHeaderComponent;

  // To know which client is selected
  public selectedBuilding: Building | null = null;

  // Search value
  public filteredBuildingsList: Building[] = [];

  // Init Filters
  agencyNameFilter: string = '';
  clientNameFilter: string = '';
  nameFilter: string = '';
  cityFilter: string = '';
  postalCodeFilter: string = '';
  filterClientType: string[] = [];
  diagnosisTypeFilter: string[] = [];
  filterYear: number | null = null;
  filterMonth: number | null = null;
  clientTypesArray = Object.entries(CLIENT_TYPES).map(([key, value]) => ({ label: key, value }));
  diagnosisTypesArray = DIAGNOSIS_TYPES;
  diagnosisNameFilter: string = '';

  constructor(public router: Router,
              private apiService: ApiService,
              private dialogService: NbDialogService,
              private authGuard: AuthGuard,
              public darkmodeService: DarkModeService,
              private dataService: DataService
  ) { }

  ngOnInit(): void {
    this.dataService.setSelectedClient(null);
    this.dataService.setSelectedAgency(null);
    this.dataService.setSelectedBuilding(null);

    // Fetch the buildings list
    this.apiService.get('buildings').subscribe(response => {
      this.buildingsList = response.data;
      this.filteredBuildingsList = [...this.buildingsList];
    });
  }

  /**
   * Function to search a client
   * @param input
   */
  public onBuildingSearch(input: string): void {
    this.filteredBuildingsList = this.buildingsList.filter(building =>
      building.building_name.toLowerCase().includes(input)
    );
  }

  /**
   * Function to apply filters
   */
  public applyFilters(): void {
    const trimmedNameFilter = this.nameFilter.trim();
    const trimmedAgencyNameFilter = this.agencyNameFilter.trim();
    const trimmedClientNameFilter = this.clientNameFilter.trim();
    const trimmedDiagnosisNameFilter = this.diagnosisNameFilter.trim();

    // Define filters
    const filters = {
      agencyNameFilter: trimmedAgencyNameFilter || '',
      clientNameFilter: trimmedClientNameFilter || '',
      nameFilter: trimmedNameFilter || '',
      cityFilter: this.cityFilter || '',
      postalCodeFilter: this.postalCodeFilter || '',
      filterClientType: this.filterClientType.length ? this.filterClientType.join(',') : '',
      diagnosisTypeFilter: this.diagnosisTypeFilter.length ? this.diagnosisTypeFilter.join(',') : '',
      filterYear: this.filterYear ? this.filterYear.toString() : '',
      filterMonth: this.filterMonth ? this.filterMonth.toString() : '',
      diagnosisNameFilter: trimmedDiagnosisNameFilter || ''
    };

    // Convert filters to query params
    const queryParams = Object.entries(filters)
      .filter(([_, value]) => value)
      .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
      .join('&');

    // Call the API to get the filtered clients
    this.apiService.get(`buildings?${queryParams}`).subscribe(response => {
      this.filteredBuildingsList = response.data || [];

      // Call reloadDiagnostics function from table header component to reload the diagnostics
      this.tableHeaderComponent.reloadDiagnostics();
    }, error => {
      console.error('Error fetching filtered buildings:', error);
      this.filteredBuildingsList = [];
    });
  }

  /**
   * Function to reset filters
   */
  public resetFilters(): void {
    this.agencyNameFilter = '';
    this.clientNameFilter = '';
    this.nameFilter = '';
    this.cityFilter = '';
    this.postalCodeFilter = '';
    this.filterClientType = [];
    this.diagnosisTypeFilter = [];
    this.filterYear = null;
    this.filterMonth = null;
    this.diagnosisNameFilter = '';
    this.applyFilters();
  }

  /**
   * Check if user has permissions
   * @param permissionKey
   */
  public hasPermission(permissionKey: string): boolean {
    const userPermissions = this.authGuard.getUserPermissions();

    if (userPermissions) {
      return userPermissions[permissionKey] && userPermissions[permissionKey] === 'WRITE';
    } else {
      return false;
    }
  }

  protected readonly DIAGNOSIS_TYPES = DIAGNOSIS_TYPES;
  protected readonly CLIENT_TYPES = CLIENT_TYPES;
}
