<div class="appointments-container">
  <Sidebar></Sidebar>
  <div class="appointments-page padding">

    <!-- APPOINTMENTS PAGE TITLE -->
    <div class="appointments-title">
      <Breadcrumb [links]="[{title: 'Mes rendez-vous', link: APPOINTMENT_URLS.list, icon: darkmodeService.getDarkModeState() ? 'calendar-light' : 'calendar-dark'}]"></Breadcrumb>
    </div>

    <ul>
      <li>
        <ParagraphAtom size="small" weight="semi-bold" [color]="darkmodeService.getDarkModeState() ? 'light' : 'false'" text="Par client"></ParagraphAtom>
        <input [(ngModel)]="clientFilter" nbInput placeholder="Client" />
      </li>
      <li>
        <ParagraphAtom size="small" weight="semi-bold" [color]="darkmodeService.getDarkModeState() ? 'light' : 'false'" text="Par immeuble"></ParagraphAtom>
        <input [(ngModel)]="buildingFilter" nbInput placeholder="Immeuble" />
      </li>
      <li>
        <ParagraphAtom size="small" weight="semi-bold" [color]="darkmodeService.getDarkModeState() ? 'light' : 'false'" text="Par date du rendez-vous"></ParagraphAtom>
        <input nbInput [nbDatepicker]="appointmentDatePicker" placeholder="Date du rendez-vous" [(ngModel)]="appointmentDateFilter">
        <nb-datepicker #appointmentDatePicker></nb-datepicker>
      </li>
      <li>
        <ParagraphAtom size="small" weight="semi-bold" [color]="darkmodeService.getDarkModeState() ? 'light' : 'false'" text="Par email"></ParagraphAtom>
        <input [(ngModel)]="emailFilter" nbInput placeholder="Email" />
      </li>
    </ul>

    <ul class="appointments-btn-filters">
      <li>
        <ParagraphAtom size="small" weight="semi-bold" [color]="darkmodeService.getDarkModeState() ? 'light' : 'false'" text="Par type de rendez-vous"></ParagraphAtom>
        <nb-select placeholder="Type de rendez-vous" [(selected)]="appointmentTypeFilter">
          <nb-option *ngFor="let type of DIAGNOSIS_TYPES" [value]="type.value">
            {{ type.label }}
          </nb-option>
        </nb-select>
      </li>
      <li>
        <button nbButton outline status="warning" (click)="resetFilters()">Réinitialiser</button>
      </li>
      <li>
        <button nbButton outline status="primary" (click)="applyFilters()">Filtrer</button>
      </li>
    </ul>

    <!-- APPOINTMENTS LIST -->
    <div class="appointments-list-container">
      <table-header *ngIf="hasPermission('appointments')" tableTitle="Rendez-vous" [searchBar]="false" addButtonContent="Ajouter un rendez-vous" [headers]="APPOINTMENT_HEADERS.permission" [dataList]="filteredAppointmentList" [params]="['client_name', 'building_name', 'appointment_date', 'diagnosis_type', 'user_email', 'status', 'actions']" (editClicked)="editAppointment($event)" (deleteClicked)="deleteAppointment($event)" (stepperClicked)="generatePDF($event)" (addClicked)="this.router.navigate([APPOINTMENT_URLS.new])" (searchInputEvent)="onAppointmentSearch($event)"></table-header>
      <table-header *ngIf="!hasPermission('appointments')" tableTitle="Rendez-vous" [searchBar]="false" [headers]="APPOINTMENT_HEADERS.noPermission" [dataList]="filteredAppointmentList" [params]="['client_name', 'building_name', 'appointment_date', 'diagnosis_type', 'status']"></table-header>
    </div>

  </div>
</div>
