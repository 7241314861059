<div class="dialog-container">
  <div>
    <TitleAtom color="black" size="small" weight="semi-bold" type="h2" text="{{ title }}"></TitleAtom>

    <!-- Dialog body content -->
    <div class="dialog-body">
      <ParagraphAtom text="{{ content }}" align="center" color="dark" size="intermediate"></ParagraphAtom>
    </div>

    <!-- Dialog action button -->
    <div>
      <button nbButton status="danger" (click)="closeDialog()">Annuler</button>
      <button *ngIf="confirmButton !== ''" nbButton status="primary" (click)="confirmAction()">{{ confirmButton }}</button>
    </div>
  </div>
</div>
