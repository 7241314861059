import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
    APPOINTMENT_URLS,
    NOTIFICATION_HEADERS,
    NOTIFICATION_STATES,
    NOTIFICATION_URLS
} from 'src/app/constants/constants';
import { Notification } from 'src/app/types/notification.type';
import { NbDialogService } from "@nebular/theme";
import { DialogComponent } from "../../molecule/dialog/dialog.component";
import {ApiService} from "../../services/api.service";
import * as moment from 'moment';
import {File} from "../../types/file.type";
import { DarkModeService } from "../../services/darkmode.service";

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {

  // Path constant
  public readonly NOTIFICATION_URLS = NOTIFICATION_URLS;

  // Constant for notification headers
  public readonly NOTIFICATION_HEADERS = NOTIFICATION_HEADERS;

  // Notifications list objects
  @Input() notificationList: Notification[] = [];

  // To know which notification is selected
  public selectedNotification: Notification | null = null;

  // Search value
  public filteredNotificationsList: Notification[] = [];

  // Init filters
  public clientFilter: string = '';
  public buildingFilter: string = '';
  public sendingDateFilter: Date | null = null;
  public creationDateFilter: Date | null = null;
  public statusFilter: string = '';
  public notificationStatusArray = [
    { label: 'transmis', value: 'sent' },
    { label: 'non transmis', value: 'not_sent' },
    { label: 'reponse reçue', value: 'answer_received'}
  ];
  public startDateFilter: Date | null = null;
  public endDateFilter: Date | null = null;
  public yearFilter: number | null = null;

  constructor(public router: Router,
              private dialogService: NbDialogService,
              private readonly apiService: ApiService,
              public darkmodeService: DarkModeService,
  ) { }

  ngOnInit(): void {
    this.apiService.get('notifications').subscribe((listOfNotifications) => {
      this.notificationList = listOfNotifications.data.map((notification: Notification) => ({
        ...notification,
        sending_at: moment.utc(notification.sending_at).format("YYYY-MM-DD HH:mm:ss")
      }));

      // Init the filtered list with formatted data
      this.filteredNotificationsList = [...this.notificationList];
    });
  }

  /**
   * Function to search a notification by Building or Client
   * @param input
   */
  public onNotificationSearch(input: string): void {
    this.filteredNotificationsList = this.notificationList.filter(notification =>
      notification.client_name.toLowerCase().includes(input) ||
      notification.building_name.toLowerCase().includes(input)
    );
  }

  /**
   * Function to filter
   */
  public applyFilters(): void {
    const trimmedClientFilter = this.clientFilter.trim();
    const trimmedBuildingFilter = this.buildingFilter.trim();

    this.filteredNotificationsList = this.notificationList.filter(notification => {
      const notificationDate = new Date(notification.sending_at);

      const matchesClient = !trimmedClientFilter || notification.client_name.toLowerCase().includes(trimmedClientFilter.toLowerCase());
      const matchesBuilding = !trimmedBuildingFilter || notification.building_name.toLowerCase().includes(trimmedBuildingFilter.toLowerCase());
      const matchesStatus = !this.statusFilter || notification.notification_status === this.statusFilter;

      const matchesStartDate = !this.startDateFilter || notificationDate >= this.startDateFilter;
      const matchesEndDate = !this.endDateFilter || notificationDate <= this.endDateFilter;
      const matchesYear = !this.yearFilter || notificationDate.getFullYear() === this.yearFilter;

      return matchesClient && matchesBuilding && matchesStartDate && matchesEndDate && matchesYear && matchesStatus;
    });
  }

  /**
   * Function to reset filters
   */
  public resetFilters(): void {
    this.clientFilter = '';
    this.buildingFilter = '';
    this.sendingDateFilter = null;
    this.creationDateFilter = null;
    this.statusFilter = '';
    this.startDateFilter = null;
    this.endDateFilter = null;
    this.yearFilter = null;
    this.applyFilters();
  }

  /**
   * Function to resend email
   * @param notification
   */
  public resendEmail(notification: Notification): void {
    const body = {
      notification_pid: notification.notification_pid,
      client_name: notification.client_name,
      notification_status: notification.notification_status,
    };
    this.apiService.post(`notifications`, body).subscribe((response) => {
    }, (error) => {
      console.error('Error resending email:', error);
    });
  }

  /**
   * Function to send user to the notification's edit page
   * @param notification
   */
  public editNotification(notification: Notification): void {
    this.selectedNotification = notification
    this.router.navigate([NOTIFICATION_URLS.edit], { queryParams: { notification: JSON.stringify(this.selectedNotification)}})
  }

  /**
   * Function to delete a notification, will first open a modal to confirm the deletion
   * @param notification
   */
  public deleteNotification(notification: Notification): void {
    const dialogRef = this.dialogService.open(DialogComponent, {
      context: {
        content: "Êtes-vous sûr de supprimer cette notification ?",
        title: "Confirmer la suppression",
        confirmButton: "Supprimer",
      },
      closeOnBackdropClick: true,
    });

    dialogRef.onClose.subscribe((result: string) => {
      if (result === 'action') {
        this.apiService.delete(`notifications/${notification.notification_pid}`).subscribe()
        this.filteredNotificationsList.splice(this.filteredNotificationsList.indexOf(notification), 1)
      }}
    )
  }

    protected readonly APPOINTMENT_URLS = APPOINTMENT_URLS;
}
