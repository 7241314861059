<div class="addresses-container">
  <Sidebar></Sidebar>
  <div class="addresses-page padding">

    <!-- CLIENTS PAGE TITLE -->
    <div class="addresses-title">
      <Breadcrumb [links]="[{title: 'Adresses', link: ADDRESSES_URLS.list, icon: darkmodeService.getDarkModeState() ? 'client-light' : 'client-dark' }]"></Breadcrumb>
    </div>

    <!-- FILTERS -->
    <ul>
      <li>
        <ParagraphAtom size="small" weight="semi-bold" [color]="darkmodeService.getDarkModeState() ? 'light' : 'false'" text="Par nom de client"></ParagraphAtom>
        <input [(ngModel)]="clientNameFilter" nbInput placeholder="Nom de client" />
      </li>
      <li>
        <ParagraphAtom size="small" weight="semi-bold" [color]="darkmodeService.getDarkModeState() ? 'light' : 'false'" text="Par nom d'agence"></ParagraphAtom>
        <input [(ngModel)]="agencyNameFilter" nbInput placeholder="Nom d'agence" />
      </li>
      <li>
        <ParagraphAtom size="small" weight="semi-bold" [color]="darkmodeService.getDarkModeState() ? 'light' : 'false'" text="Par adresse"></ParagraphAtom>
        <input [(ngModel)]="nameFilter" nbInput placeholder="Adresse" />
      </li>
      <li>
        <ParagraphAtom size="small" weight="semi-bold" [color]="darkmodeService.getDarkModeState() ? 'light' : 'false'" text="Par ville"></ParagraphAtom>
        <input [(ngModel)]="cityFilter" nbInput placeholder="Ville" />
      </li>
      <li>
        <ParagraphAtom size="small" weight="semi-bold" [color]="darkmodeService.getDarkModeState() ? 'light' : 'false'" text="Par code postal"></ParagraphAtom>
        <input [(ngModel)]="postalCodeFilter" nbInput placeholder="Code postal" />
      </li>
    </ul>
    <ul>
      <li>
        <ParagraphAtom size="small" weight="semi-bold"
                       [color]="darkmodeService.getDarkModeState() ? 'light' : 'false'"
                       text="Par type de client">
        </ParagraphAtom>
        <nb-select placeholder="Type" [(selected)]="filterClientType" multiple>
          <nb-option *ngFor="let type of clientTypesArray" [value]="type.value">
            {{ type.label }}
          </nb-option>
        </nb-select>
      </li>
      <li>
        <ParagraphAtom size="small" weight="semi-bold" [color]="darkmodeService.getDarkModeState() ? 'light' : 'false'" text="Par numéro de dossier"></ParagraphAtom>
        <input [(ngModel)]="diagnosisNameFilter" nbInput placeholder="Numéro de dossier" />
      </li>
      <li>
        <ParagraphAtom size="small" weight="semi-bold" [color]="darkmodeService.getDarkModeState() ? 'light' : 'false'" text="Par type de diagnostic"></ParagraphAtom>
        <nb-select placeholder="Type de diagnostic" [(selected)]="diagnosisTypeFilter" multiple>
          <nb-option *ngFor="let diagnosisType of diagnosisTypesArray" [value]="diagnosisType.value">
            {{ diagnosisType.label }}
          </nb-option>
        </nb-select>
      </li>
      <li>
        <ParagraphAtom size="small" weight="semi-bold" [color]="darkmodeService.getDarkModeState() ? 'light' : 'false'" text="Par mois"></ParagraphAtom>
        <nb-select placeholder="Mois" [(selected)]="filterMonth">
          <nb-option *ngFor="let month of MONTHS_LIST" [value]="month.value">
            {{ month.label }}
          </nb-option>
        </nb-select>
      </li>
      <li>
        <ParagraphAtom size="small" weight="semi-bold" [color]="darkmodeService.getDarkModeState() ? 'light' : 'false'" text="Par année"></ParagraphAtom>
        <input type="number" nbInput placeholder="Année (ex. 2023)" [(ngModel)]="filterYear">
      </li>
    </ul>
    <ul class="addresses-btn-list">
      <li>
        <button nbButton outline status="warning" (click)="resetFilters()">Réinitialiser</button>
      </li>
      <li>
        <button nbButton outline status="primary" (click)="applyFilters()">Filtrer</button>
      </li>
    </ul>

    <!-- CLIENTS LIST -->
    <div class="addresses-list-container">
      <table-header #tableHeaderComponent
                    [diagnosisTypeFilter]="diagnosisTypeFilter"
                    [diagnosisNameFilter]="diagnosisNameFilter"
                    [filterYear]="filterYear"
                    [filterMonth]="filterMonth" [searchBar]="false" *ngIf="hasPermission('adresses')" [isClickable]="false" tableTitle="Adresses" [headers]="ADDRESSES_HEADERS.permission" [dataList]="filteredBuildingsList" [params]="['building_name', 'building_postalCode', 'building_city', 'total_diagnostics']"  (searchInputEvent)="onBuildingSearch($event)"></table-header>
      <table-header #tableHeaderComponent
                    [diagnosisTypeFilter]="diagnosisTypeFilter"
                    [diagnosisNameFilter]="diagnosisNameFilter"
                    [filterYear]="filterYear"
                    [filterMonth]="filterMonth" [searchBar]="false" *ngIf="!hasPermission('adresses')" [isClickable]="false" tableTitle="Adresses" [headers]="ADDRESSES_HEADERS.permission" [dataList]="filteredBuildingsList" [params]="['building_name', 'building_postalCode', 'building_city', 'total_diagnostics']"></table-header>
    </div>
  </div>
</div>
