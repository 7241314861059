<div class="buildings-container">
  <Sidebar></Sidebar>
  <div class="buildings-page padding">

    <!-- BUILDINGS PAGE TITLE -->
    <div class="buildings-title" *ngIf="selectedClient">
      <Breadcrumb *ngIf="selectedAgency"
                  [links]="[
            {title: selectedClient?.client_name || '', link: CLIENT_URLS.list, icon: 'client-grey', permission: 'clients'},
            {title: selectedAgency?.agency_name || '', link: AGENCY_URLS.list, icon: 'agency-grey', queryParams: { client: selectedClient?.client_pid }, permission: 'agencies'},
            {title: 'Immeubles', link: BUILDING_URLS.list, icon: darkmodeService.getDarkModeState() ? 'building-light' : 'building-dark', queryParams: selectedClient && selectedClient.client_pid ? { client: selectedClient?.client_pid } : undefined, permission: 'buildings'}
          ]">
      </Breadcrumb>
      <Breadcrumb *ngIf="!selectedAgency"
                  [links]="[
            {title: selectedClient?.client_name || '', link: CLIENT_URLS.list, icon: 'client-grey', permission: 'clients'},
            {title: 'Immeubles', link: BUILDING_URLS.list, icon: darkmodeService.getDarkModeState() ? 'building-light' : 'building-dark', queryParams: selectedClient && selectedClient.client_pid ? { client: selectedClient?.client_pid } : undefined, permission: 'buildings'}
          ]">
      </Breadcrumb>
      <div *ngIf="userClientType !== CLIENT_TYPES.PARTICULIER && permissionsUser.agencies !== 'NONE' && permissionsUser.clients !== 'NONE'">
        <button nbButton (click)="return()"><nb-icon icon="arrow-ios-back-outline"></nb-icon>Retour</button>
        <app-comments-modal *ngIf="selectedAgency" [resourcePid]="selectedAgency?.agency_pid!"></app-comments-modal>
        <app-comments-modal *ngIf="!selectedAgency" [resourcePid]="selectedClient?.client_pid!"></app-comments-modal>
      </div>
    </div>

    <!-- FILTERS -->
    <ul>
      <li>
        <ParagraphAtom size="small" weight="semi-bold" [color]="darkmodeService.getDarkModeState() ? 'light' : 'false'" text="Par adresse"></ParagraphAtom>
        <input [(ngModel)]="addressFilter" nbInput placeholder="Adresse" />
      </li>
      <li>
        <ParagraphAtom size="small" weight="semi-bold" [color]="darkmodeService.getDarkModeState() ? 'light' : 'false'" text="Par code postal"></ParagraphAtom>
        <input [(ngModel)]="postalCodeFilter" nbInput placeholder="Code Postal" />
      </li>
      <li>
        <ParagraphAtom size="small" weight="semi-bold" [color]="darkmodeService.getDarkModeState() ? 'light' : 'false'" text="Par ville"></ParagraphAtom>
        <input [(ngModel)]="cityFilter" nbInput placeholder="Ville" />
      </li>
      <li *ngIf="selectedAgency">
        <ParagraphAtom size="small" weight="semi-bold" [color]="darkmodeService.getDarkModeState() ? 'light' : 'false'" text="Par email du gestionnaire"></ParagraphAtom>
        <input [(ngModel)]="managerEmailFilter" nbInput placeholder="Email du gestionnaire" />
      </li>
    </ul>

    <ul class="buildings-filters-btn">
      <li *ngIf="selectedAgency">
        <ParagraphAtom size="small" weight="semi-bold" [color]="darkmodeService.getDarkModeState() ? 'light' : 'false'" text="Par nom du gestionnaire"></ParagraphAtom>
        <input [(ngModel)]="managerLastNameFilter" nbInput placeholder="Nom du gestionnaire" />
      </li>
      <li *ngIf="selectedAgency">
        <ParagraphAtom size="small" weight="semi-bold" [color]="darkmodeService.getDarkModeState() ? 'light' : 'false'" text="Par prénom du gestionnaire"></ParagraphAtom>
        <input [(ngModel)]="managerFirstNameFilter" nbInput placeholder="Prénom du gestionnaire" />
      </li>
      <li>
        <ParagraphAtom size="small" weight="semi-bold" [color]="darkmodeService.getDarkModeState() ? 'light' : 'false'" text="Par nombre de diagnostics"></ParagraphAtom>
        <nb-select [(selected)]="sortOrder" placeholder="Ordre de tri des diagnostics">
          <nb-option value="asc">Croissant</nb-option>
          <nb-option value="desc">Décroissant</nb-option>
        </nb-select>
      </li>
      <li>
        <button nbButton outline status="warning" (click)="resetFilters()">Réinitialiser</button>
      </li>
      <li>
        <button nbButton outline status="primary" (click)="applyFilters()">Filtrer</button>
      </li>
    </ul>

    <!-- BUILDINGS LIST -->
    <div class="buildings-list-container">
      <table-header [searchBar]="false" *ngIf="!this.selectedAgency && hasPermission('agencies')" [isClickable]="true" (rowClicked)="navigateToDiagnoses(this.selectedClient!, $event)" tableTitle="Immeubles" addButtonContent="Ajouter un immeuble" [headers]="BUILDING_HEADERS.permission" [dataList]="filteredBuildingsList" [params]="['building_address', 'building_postalCode', 'building_city', 'diagnosis_count', 'actions']" (editClicked)="editBuilding($event)" (deleteClicked)="deleteBuilding($event)" (addClicked)="navigateToCreateBuilding()" (searchInputEvent)="onBuildingSearch($event)"></table-header>
      <table-header [searchBar]="false" *ngIf="this.selectedAgency && hasPermission('agencies')" [isClickable]="true" (rowClicked)="navigateToDiagnoses(this.selectedClient!, $event)" tableTitle="Immeubles" addButtonContent="Ajouter un immeuble" [headers]="BUILDING_HEADERS.permissionManager" [dataList]="filteredBuildingsList" [params]="['building_address', 'building_postalCode', 'building_city', 'gestionnaire_lastName', 'gestionnaire_firstName', 'gestionnaire_email', 'diagnosis_count', 'actions']" (editClicked)="editBuilding($event)" (deleteClicked)="deleteBuilding($event)" (addClicked)="navigateToCreateBuilding()" (searchInputEvent)="onBuildingSearch($event)"></table-header>
      <table-header [searchBar]="false" *ngIf="!this.selectedAgency && !hasPermission('agencies')" [isClickable]="true" (rowClicked)="navigateToDiagnoses(this.selectedClient!, $event)" tableTitle="Immeubles" [headers]="BUILDING_HEADERS.noPermissionNoManager" [dataList]="filteredBuildingsList" [params]="['building_address', 'building_postalCode', 'building_city', 'diagnosis_count']" (searchInputEvent)="onBuildingSearch($event)"></table-header>
      <table-header [searchBar]="false" *ngIf="this.selectedAgency && !hasPermission('agencies')" [isClickable]="true" (rowClicked)="navigateToDiagnoses(this.selectedClient!, $event)" tableTitle="Immeubles" [headers]="BUILDING_HEADERS.noPermissionManager" [dataList]="filteredBuildingsList" [params]="['building_address', 'building_postalCode', 'building_city', 'gestionnaire_lastName', 'gestionnaire_firstName', 'gestionnaire_email', 'diagnosis_count']" (searchInputEvent)="onBuildingSearch($event)"></table-header>
    </div>

  </div>
</div>
