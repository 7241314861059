import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import {
  BUILDING_URLS,
  CLIENT_URLS,
  AGENCY_URLS,
  DIAGNOSIS_URLS,
  FILE_URLS,
  DIAGNOSIS_TYPES,
  DIAGNOSIS_HEADERS, DATE_FILTER, MONTHS_LIST
} from "../../constants/constants";
import { Client } from 'src/app/types/client.type';
import { Agency } from 'src/app/types/agency.type';
import { Building } from 'src/app/types/building.type';
import { Diagnose } from 'src/app/types/diagnosis.type';
import { ApiService } from "../../services/api.service";
import { NbDialogService } from "@nebular/theme";
import { DialogComponent } from "../../molecule/dialog/dialog.component";
import { AuthGuard } from "../../services/auth.guard";
import { DataService } from "../../services/data.service";
import { DarkModeService } from "../../services/darkmode.service";

@Component({
  selector: 'app-diagnoses',
  templateUrl: './diagnoses.component.html',
  styleUrls: ['./diagnoses.component.scss']
})
export class DiagnosesComponent implements OnInit {

  // Filtering values
  filterYear: number | null = null;
  specificDate: Date | null = null;
  filterMonth: number | null = null;
  filterTypes: string[] = [];
  filterName: string = '';
  sortOrder: string = 'none';

  // Path and types constant
  public readonly CLIENT_URLS = CLIENT_URLS;
  public readonly AGENCY_URLS = AGENCY_URLS;
  public readonly BUILDING_URLS = BUILDING_URLS;
  public readonly DIAGNOSIS_URLS = DIAGNOSIS_URLS;
  public readonly DIAGNOSIS_TYPES = DIAGNOSIS_TYPES;
  public readonly DATE_FILTER = DATE_FILTER;
  public readonly MONTHS_LIST = MONTHS_LIST;

  // Constant for diagnoses headers
  public readonly DIAGNOSIS_HEADERS = DIAGNOSIS_HEADERS;

  // Diagnoses list objects
  public diagnosesList: Diagnose[] = [];

  // To know which client is selected
  public selectedClient: Client | null = null;

  // To know which agency is selected
  public selectedAgency: Agency | null = null;

  // To know which building is selected
  public selectedBuilding: Building | null = null;
  public buildingPid: string | undefined = '';
  public clientPid: string | undefined = '';
  public agencyPid: string | undefined = '';

  // Search value
  public filteredDiagnosesList: Diagnose[] = [];

  constructor(public router: Router,
              private route: ActivatedRoute,
              private apiService: ApiService,
              private dialogService: NbDialogService,
              private authGuard: AuthGuard,
              private dataService: DataService,
              public darkmodeService: DarkModeService) { }

  ngOnInit(): void {
    const userPermissions = this.authGuard.getUserPermissions();

    this.route.queryParams.subscribe(params => {
      this.buildingPid = params['building'];
      this.clientPid = params['client'];
      this.agencyPid = params['agency'];

      this.dataService.getSelectedClient().subscribe(client => {
        this.selectedClient = client;
      });

      this.loadAgencyData();

      if (this.buildingPid) {
        this.dataService.getSelectedBuilding().subscribe(building => {
          this.selectedBuilding = building;
        });

        this.apiService.get(`buildings/${this.buildingPid}/diagnoses`).subscribe(response => {
          this.diagnosesList = response.data;

          // Sort alphabetically
          this.diagnosesList.sort((a, b) => a.diagnosis_name.localeCompare(b.diagnosis_name));
          this.filteredDiagnosesList = [...this.diagnosesList];
        });
      }
    });
  }

  private loadAgencyData(): void {
    if (this.agencyPid) {
      this.apiService.get(`agencies/${this.agencyPid}`).subscribe(response => {
        this.selectedAgency = response.data[0];
        this.dataService.setSelectedAgency(response.data[0]);
      });
    } else {
      this.dataService.getSelectedAgency().subscribe(agency => {
        this.selectedAgency = agency;
      });
    }
  }

  /**
   * Function to filter diagnosis by filter
   */
  applyDateFilter(): void {
    const trimmedFilterName = this.filterName.trim().toLowerCase();

    this.filteredDiagnosesList = this.diagnosesList.filter(diagnosis => {
      //const diagnosisDate = this.convertDate(diagnosis.start_of_contract);
      const diagnosisDate = this.convertDate(diagnosis.end_of_contract);

      const matchYear = this.filterYear ? diagnosisDate.getFullYear() === this.filterYear : true;
      const matchMonth = this.filterMonth ? diagnosisDate.getMonth() + 1 === this.filterMonth : true;
      const matchTypes = this.filterTypes.length > 0 ? this.filterTypes.includes(diagnosis.diagnosis_type) : true;
      const matchName = trimmedFilterName ? diagnosis.diagnosis_name.toLowerCase().includes(trimmedFilterName) : true;

      // If a specific date is selected, we filter by this date
      return matchYear && matchMonth && matchTypes && matchName;
    });

    if (this.sortOrder === 'asc') {
      this.filteredDiagnosesList.sort((a, b) => this.compareDates(a.end_of_contract, b.end_of_contract, true));
    } else if (this.sortOrder === 'desc') {
      this.filteredDiagnosesList.sort((a, b) => this.compareDates(a.end_of_contract, b.end_of_contract, false));
    }
  }

  /**
   * Function to sort the list of diagnoses by date
   * @param date1 - date 1 to compare
   * @param date2 - date 2 to compare
   * @param isAscending - boolean to know if we sort in ascending order
   * @private
   */
  private compareDates(date1: string, date2: string, isAscending: boolean): number {
    const d1 = this.convertDate(date1);
    const d2 = this.convertDate(date2);

    if (d1 < d2) {
      return isAscending ? -1 : 1;
    } else if (d1 > d2) {
      return isAscending ? 1 : -1;
    } else {
      return 0;
    }
  }

  /**
   * Get the diagnosis name enter by user
   * @param event
   */
  public onNameSearch(event: Event): void {
    this.filterName = (event.target as HTMLInputElement).value.toLowerCase();
  }

  /**
   * Convert string to Date object
   * @param dateStr
   * @private
   */
  private convertDate(dateStr: string): Date {
    const parts = dateStr.split('/');
    return new Date(`${parts[2]}-${parts[1]}-${parts[0]}`);
  }

  /**
   * Check if the same date for filter by specific date
   * @param date1
   * @param date2
   */
  private isSameDate(date1: Date, date2: Date): boolean {
    return date1.getDate() === date2.getDate() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getFullYear() === date2.getFullYear();
  }

  /**
   * Function to reset filters
   */
  public resetDateFilter(): void {
    this.filterYear = null;
    this.specificDate = null;
    this.filterTypes = [];
    this.filterName = '';
    this.sortOrder = 'none';
    this.filteredDiagnosesList = [...this.diagnosesList];
  }

  /**
   * Function to navigate to file
   */
  public navigateToFiles(diagnosis: Diagnose) {
    this.dataService.setSelectedDiagnosis(diagnosis);
    if (this.selectedAgency) {
      this.router.navigate([FILE_URLS.list], { queryParams: { diagnosis: diagnosis.diagnosis_pid } })
    } else {
      this.router.navigate([FILE_URLS.list], { queryParams: { diagnosis: diagnosis.diagnosis_pid } });
    }
  }

  /**
   * Function to get the diagnosis selected
   * @param diagnosis
   */
  public editDiagnosis(diagnosis: Diagnose): void {
    if (this.selectedAgency) {
      this.router.navigate([DIAGNOSIS_URLS.edit], { queryParams: { diagnosis: JSON.stringify(diagnosis), client: JSON.stringify(this.selectedClient), building: JSON.stringify(this.selectedBuilding), agency: JSON.stringify(this.selectedAgency) } });
    } else {
      this.router.navigate([DIAGNOSIS_URLS.edit], { queryParams: { diagnosis: JSON.stringify(diagnosis), client: JSON.stringify(this.selectedClient), building: JSON.stringify(this.selectedBuilding) } });
    }
  }

  /**
   * Function to delete the selected diagnosis, will first open a modal to confirm the deletion
   * @param diagnosis
   */
  public deleteDiagnosis(diagnosis: Diagnose): void {
    const dialogRef = this.dialogService.open(DialogComponent, {
      context: {
        content: "Êtes-vous sûr de supprimer ce diagnostic ?",
        title: "Confirmer la suppression",
        confirmButton: "Supprimer",
      },
      closeOnBackdropClick: true,
    });

    dialogRef.onClose.subscribe((result: string) => {
      if (result === 'action') {
        this.apiService.delete(`diagnoses/${diagnosis.diagnosis_pid}`).subscribe()
        this.filteredDiagnosesList.splice(this.filteredDiagnosesList.indexOf(diagnosis), 1);
      }
    })
  }

  /**
   * Function to navigate to the diagnosis creation page
   */
  public navigateToCreateDiagnosis(): void {
    if (this.selectedAgency) {
      this.router.navigate([DIAGNOSIS_URLS.new], { queryParams: { client: JSON.stringify(this.selectedClient), agency: JSON.stringify(this.selectedAgency), building: JSON.stringify(this.selectedBuilding) } });
    } else {
      this.router.navigate([DIAGNOSIS_URLS.new], { queryParams: { client: JSON.stringify(this.selectedClient), building: JSON.stringify(this.selectedBuilding) } });
    }
  }

  /**
   * Function to back to the buildings page with the client selected
   */
  public backToBuildings(): void {
    if (this.selectedAgency) {
      this.router.navigate([BUILDING_URLS.list], { queryParams: {client: this.selectedClient?.client_pid, agency:this.selectedAgency.agency_pid } })
    } else {
      this.router.navigate([BUILDING_URLS.list], { queryParams: { client: this.selectedClient?.client_pid } });
    }
  }

  /**
   * Function to stringify a JSON object
   */
  public stringify(object: Client | Agency | null): string {
    return JSON.stringify(object);
  }

  /**
   * Check if user has permissions
   * @param permissionKey
   */
  public hasPermission(permissionKey: string): boolean {
    const userPermissions = this.authGuard.getUserPermissions();

    if (userPermissions) {
      return userPermissions[permissionKey] && userPermissions[permissionKey] === 'WRITE';
    } else {
      return false;
    }
  }
}
